import React, { useEffect } from "react";
import NavigationItem from "./NavigationItem";
import "./navigation.css";
import { useAppDispatch } from "../../hooks/hooks";
import { clearTokens } from "../../state/tokens/tokens";
import { clearSwap } from "../../state/swap/swap";
import Modal from "../../components/Modal/Modal";

import { reset } from '../../state/connect/connect'
// import { changeAccount, changeChain, makeConnection, reset } from './state/connect/connect';

interface INavigationProps {
    selected: number;
    update: Function;
}

const Navigation: React.FC<INavigationProps> = ({ selected, update }) => {
    const dispatch = useAppDispatch();
    const [modal, setModal] = React.useState<boolean>(false);

    function handleClick(num: number) {
        dispatch(clearTokens());
        dispatch(clearSwap());
        update(num);

        if (num === 2) {
            const disclaimer = localStorage.getItem('disclaimer')
            if (disclaimer === null) {
                localStorage.setItem('disclaimer', '1')
                setModal(true)
            } 
        }
    }

    return (
        <div className="navigation">
            <NavigationItem label="Swap for Multiple" active={selected === 1} onClick={() => handleClick(1)} />
            <NavigationItem label="Sell Non-Tax Tokens" active={selected === 2} onClick={() => handleClick(2)} />
            {modal &&
            <Modal close={() => setModal(false)} open={modal}>
                <div className="disconnect-modal">
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <span>I understand that CoinSwap is a new swapping platform that routes through Uniswap. I understand that, at this time, selling ERC tokens with taxed tokenomics will cause the tax on said tokens to occur twice, once by CoinSwap, and once by Uniswap.</span>
                </div>
                    <div className="modal-actions">
                        <button onClick={() => setModal(false)} className="disconnect-modal-action">
                            Confirm
                        </button>
                        <button onClick={() => { dispatch(reset()); setModal(false); }} className="disconnect-modal-action-cancel">
                            Cancel
                        </button>
                    </div>              
                </div>
            </Modal>
      }
        </div>
    )
}

export default Navigation;